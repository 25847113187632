<template>
  <div style="display: inline-block">
    <div @click="showDialog()">
      <slot></slot>
    </div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          <span>Edit Attachment</span>
          <v-spacer></v-spacer>
          <v-btn icon text @click.stop="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1">
          <v-row>
            <v-col md="12">
              <v-text-field v-model="selectedItemData.title" dense outlined hide-details class="mb-2" label="Title"></v-text-field>
              <v-textarea v-model="selectedItemData.description" dense outlined hide-details class="mb-2" label="Description" rows="2"></v-textarea>
              <v-text-field append-icon="mdi-upload" @click:append="uploadFile()" v-model="selectedItemData.file_src" dense outlined hide-details class="mb-2" label="File Src"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="selectItem()">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="light" @click.stop="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />
  </div>
</template>

<script>
export default {
  props: ["selectedItemData", "callback"],
  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      listItems: [],
      page: 1,
      totalPage: 1,
      totalResults: 1,
      // selectedItemData: {
      //   title: "",
      //   description: "",
      //   file_src: "",
      // },

      fileupload: null,
    };
  },

  methods: {
    showDialog() {
      this.dialog = true;
    },

    selectItem() {
      if (typeof this.selectedItemData !== "undefined") {
        this.callback(this.selectedItemData);
        this.dialog = false;
      }
    },

    uploadFile() {
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      this.selectedItemData.file_src = "";

      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("beritaacara/upload-attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.selectedItemData.file_src = resData.data.file_src;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() { },
};
</script>
