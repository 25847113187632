<template>
  <div style="display: inline-block">
    <div @click="showDialog()">
      <slot></slot>
    </div>
    <v-dialog v-model="dialog" max-width="1200" persistent>
      <v-card>
        <v-card-title>
          <span>Add Item</span>
          <v-spacer></v-spacer>
          <v-btn icon text @click.stop="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text class="pt-1">
          <v-row>
            <v-col md="4">
              <v-card elevation="0">
                <v-card-text class="pb-0">
                  <v-text-field outlined dense placeholder="Search Item" label="Search Item" v-model="search" append-icon="mdi-magnify" @click:append="findItems()" @keyup.enter="findItems()" hide-details="" autofocus clearable></v-text-field>

                  <div class="d-flex py-2 my-0" v-if="loading">Loading...</div>

                  <div class="d-flex py-2 my-0" v-if="!loading">
                    <span style="line-height: 36px">Found {{ totalResults }} Records</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="prevPage()" text color="primary" class="mx-1">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <span style="line-height: 36px">Page {{ page }} From {{ totalPage }}</span>
                    <v-btn icon @click="nextPage()" text color="primary" class="mx-1">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
                <v-divider class="mx-2 mb-2"></v-divider>
                <v-sheet max-height="400" min-height="400" style="overflow-y: auto" class="px-2">
                  <div>
                    <div class="text-center py-1" v-if="listItems.length < 1 && !loading">
                      <v-alert color="error" dark>Record not found!</v-alert>
                    </div>
                    <div class="text-center py-1 fill-height" v-if="loading">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <v-list-item-group v-if="!loading" elevation="1" v-model="selectedItem">
                      <v-list-item v-for="(item, i) in listItems" :key="i" dense style="border-bottom: 1px solid #ddd">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.item_no_ }}</v-list-item-title>
                          <v-list-item-subtitle class="d-flex">
                            <span>{{ item.item_description }}</span>
                            <v-spacer></v-spacer>
                            <span>{{ item.unit_of_measure_code }}</span>
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="d-flex">
                            <div>Qty: {{ item.quantity_base }}</div>
                            <v-spacer></v-spacer>
                            <div>Wght: {{ item.item_weight }}</div>
                          </v-list-item-subtitle>
                          
                          <v-list-item-subtitle class="d-flex">
                            <div>Loc: {{ item.location_code }}</div>
                            <v-spacer></v-spacer>
                            <div>Bin: {{ item.bin_code }}</div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </div>
                </v-sheet>
              </v-card>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col md="4">
              <v-card elevation="0">
                <v-card-text class="pb-0">
                  <v-text-field v-model="selectedItemData.location_code" dense outlined hide-details disabled class="mb-2" label="Location Code"></v-text-field>
                  <v-text-field v-model="selectedItemData.bin_code" dense outlined hide-details disabled class="mb-2" label="Bin Code"></v-text-field>
                  <v-text-field v-model="selectedItemData.item_no_" dense outlined hide-details disabled class="mb-2" label="Item No."></v-text-field>
                  <v-text-field v-model="selectedItemData.item_description" dense outlined hide-details disabled class="mb-2" label="Item Description"></v-text-field>
                  <v-text-field v-model="selectedItemData.unit_of_measure" dense outlined hide-details disabled class="mb-2" label="UoM"></v-text-field>
                  <v-text-field v-model="selectedItemData.unit_price" dense outlined hide-details disabled class="mb-2" label="Unit Price"></v-text-field>
                  <v-text-field v-model="selectedItemData.weight" dense outlined hide-details disabled class="mb-2" label="Weight"></v-text-field>
                  <v-text-field v-model="selectedItemData.quantity" dense outlined hide-details class="mb-2" label="Quantity"></v-text-field>
                  <v-textarea v-model="selectedItemData.description" dense outlined hide-details class="mb-2" label="Description" multi-line rows="2"></v-textarea>
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col md="4">
              <v-card elevation="0" class="fill-height">
                <v-card-text class="pt-1 px-2">
                  <div class="mt-2 fill-height">
                    <v-card elevation="1" class="fill-height" outlined>
                      <v-card-title>Preview</v-card-title>
                      <v-divider></v-divider>
                      <a :href="resourceUrl(selectedItemData.item_image)" target="_blank">
                        <v-img :src="resourceUrl(selectedItemData.item_image)" min-height="300" max-height="350"></v-img>
                      </a>
                    </v-card>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="blue" dark dense block @click="uploadImage()">Upload Image</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="selectItem()">Save Item</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="light" @click.stop="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input type="file" style="display: none" accept="image/*" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />
  </div>
</template>

<script>
export default {
  props: ["document_date", "addItemCallback"],
  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      selectedItem: null,
      listItems: [],
      page: 1,
      totalPage: 1,
      totalResults: 1,
      selectedItemData: {},

      fileupload: null,
    };
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedItem(newVal, oldVal) {
      this.selectedItemData = {};
      if (typeof newVal !== "undefined" && newVal != -1) {
        var i = this.selectedItem;
        var d = this.listItems;
        if (typeof d[i] !== "undefined") {
          var selectedItemData = d[i];

          this.selectedItemData = {
            item_no_: selectedItemData.item_no_,
            item_description: selectedItemData.item_description,
            unit_of_measure: selectedItemData.unit_of_measure_code,
            quantity: selectedItemData.quantity_base,
            weight: selectedItemData.item_weight,
            unit_price: selectedItemData.unit_price,
            location_code: selectedItemData.location_code,
            bin_code: selectedItemData.bin_code,
            description: "",
            item_image: "",
          };
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    dialog(newVal, oldVal) {
      if (newVal === true) {
        this.selectedItemData = {};
      }
    },
  },

  methods: {
    showDialog() {
      this.dialog = true;
    },

    async findItems(currentPage = 1) {
      this.selectedItem = null;
      this.selectedItemData = {};
      this.loading = true;
      var config = {
        cacheConfig: false,
        params: {
          search: this.search,
          page: currentPage,
          document_date: this.document_date,
        },
      };
      await this.$axios
        .get("/beritaacara/find-item", config)
        .then((res) => {
          var resData = res.data;

          this.listItems = resData.data.results;
          this.page = resData.data.page;
          this.totalPage = resData.data.total_page;
          this.totalResults = resData.data.total_results;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    prevPage() {
      if (this.page <= 1) {
        this.page = 1;
        return;
      } else {
        this.page = this.page - 1;
      }
      this.findItems(this.page);
    },

    nextPage() {
      if (this.page >= this.totalPage) {
        this.page = this.totalPage;
        return;
      } else {
        this.page = this.page + 1;
      }
      this.findItems(this.page);
    },

    selectItem() {
      if (typeof this.selectedItemData !== "undefined") {
        this.addItemCallback(this.selectedItemData);
        this.dialog = false;
      }
    },

    uploadImage() {
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("beritaacara/upload-image-item", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.selectedItemData.item_image = resData.data.file_src;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.findItems();
  },
};
</script>
